import React, { Fragment } from 'react'
import { bool, func, shape } from 'prop-types'
import ModalHeaderForm from '../ModalHeaderForm/ModalHeaderForm'
import Modal from '../Modal'
import { useSelector, useDispatch } from 'react-redux'
import ConfigConsumer from '../../redux/configuration/context'
import { selectLabels, selectFields } from '../../redux/configuration/selectors'
import {
  formatPriceByLanguage,
  filterFieldsByName,
  getSchemaFromFieldDetails,
  getInitialValuesFromFields
} from '../../redux/configuration/utilities'
import { ENABLE_ACCOMMODATIONS } from '../../configuration/constants'
import {
  getPassengerNamesList,
  normalizeLabelWithCount,
  capitalizeFirstLetter
} from '../../configuration/utilities'
import { bookingStatusCodes } from '../../configuration/statusCodes'
import { selectLanguage, selectSessionId } from '../../redux/session/selectors'
import { sendEmailConfirmation } from '../../redux/user/actions'
import {
  selectSendingEmailConfirmation,
  selectSendEmailConfirmationSuccess,
  selectSendEmailConfirmationError,
  selectCustomerAccount
} from '../../redux/user/selectors'
import { getDateValue } from '../summary/utilities'

import { getRouteBreakdown } from '../../redux/selectors/selectSummaryPriceBreakdown'
import SvgIcon from '../SvgIcon/SvgIcon'
import './BookingDetailsModal.scss'

const Trip = ({ trip, labels }) => {
  const language = useSelector(selectLanguage)
  if (!trip) return null

  const passengers = trip?.passengers?.booked || []
  const passengerNames = getPassengerNamesList(labels, passengers)
  const passengerPrice = trip?.passengerPriceLines?.[0]?.price
  const vehiclePrice = trip?.vehiclePriceLines?.[0]?.price
  const vehicleLength = trip?.vehicles?.booked?.[0]?.length
  const vehicleResourceCode = trip?.vehiclePriceLines?.[0]?.resourceCode
  let vehicleLineLabel =
    vehicleResourceCode && labels[vehicleResourceCode]
      ? labels[vehicleResourceCode]
      : labels.noVehicle
  if (vehiclePrice && vehicleLength && +vehicleLength > 21) {
    const fixedWithZeros = (+vehicleLength).toFixed(2)
    const stringWithoutZeros = fixedWithZeros.toString()
    vehicleLineLabel = `${vehicleLineLabel} (${+stringWithoutZeros} ${
      labels.feet
    })`
  }
  const tripDate = getDateValue(trip, labels, language)

  const { taxesAndFeesSections, discountSection } = getRouteBreakdown(
    trip,
    labels,
    language,
    passengers,
    null,
    vehicleLength
  )

  const accommodationsPrice = ENABLE_ACCOMMODATIONS
    ? trip?.accommodationPriceLines?.[0]?.price
    : 0
  const accommodationsAmount = ENABLE_ACCOMMODATIONS
    ? trip?.accommodations?.amount
    : 0
  const { routeCode, shipCode, departurePriceData } = trip
  return (
    <div className="trip-details">
      <ConfigConsumer>
        {({ themeVariables }) => (
          <Fragment>
            <h3 className="title">{labels?.[`${routeCode}Caption`] || ''}</h3>
            <div className="ticket-info">
              {labels[shipCode] && (
                <div className="ticket-info-line">
                  <p className="ticket-info-name">{labels.ship}:</p>
                  <p>{labels[shipCode]}</p>
                </div>
              )}
              <div className="ticket-info-line">
                <p>
                  <strong>
                    {labels.date ? capitalizeFirstLetter(labels.date) : ''}:
                  </strong>{' '}
                  {tripDate}
                </p>
              </div>
            </div>
            <div className="pricing-details">
              {!(passengerNames && passengerPrice) ? null : (
                <div className="pricing-details-line">
                  <SvgIcon
                    type="passenger"
                    className="ticket-info-icon"
                    fill={themeVariables.themeIconColor}
                  />
                  <p>
                    <strong>{labels.passengers}</strong>
                    <br />
                    {passengerNames.map(
                      ({ name, namePrefix }, passengerNameIndex) => (
                        <Fragment
                          key={`${namePrefix}-${name}-${passengerNameIndex}`}
                        >
                          <span className="passenger-name-prefix">
                            {namePrefix}:{' '}
                          </span>
                          {name}
                          <br />
                        </Fragment>
                      )
                    )}
                  </p>
                  <p className="line-amount">
                    {formatPriceByLanguage(passengerPrice, language)}
                  </p>
                </div>
              )}
              <div className="pricing-details-line">
                <SvgIcon
                  type="vehicle"
                  className="ticket-info-icon"
                  fill={themeVariables.themeIconColor}
                />
                <p>
                  <strong>{labels.vehicles}</strong>
                  <br />
                  {vehicleLineLabel}
                </p>
                <p className="line-amount">
                  {formatPriceByLanguage(vehiclePrice, language)}
                </p>
              </div>
              {ENABLE_ACCOMMODATIONS && (
                <div className="pricing-details-line">
                  <SvgIcon
                    type="accommodations"
                    className="ticket-info-icon"
                    fill={themeVariables.themeIconColor}
                  />
                  <p>
                    <strong>{labels.accommodations}</strong>
                    <br />
                    {accommodationsAmount > 0
                      ? normalizeLabelWithCount({
                          labelKey: 'servicesTimeLineLabel',
                          pluralLabelKey: 'servicesTimeLineLabelPlural',
                          language,
                          count: accommodationsAmount,
                          showCountWhenOne: true,
                          labels
                        })
                      : labels.noAccommodationsSelected}
                  </p>
                  <p className="line-amount">
                    {formatPriceByLanguage(accommodationsPrice, language)}
                  </p>
                </div>
              )}
              {discountSection != null && (
                <div className="pricing-details-line">
                  <p>
                    <strong>{labels.discounts}</strong>
                  </p>
                  <p className="line-amount">{discountSection.cost}</p>
                </div>
              )}
              {taxesAndFeesSections?.length ? (
                <div className="tax-lines">
                  {taxesAndFeesSections.map(({ value, cost }, index) => (
                    <div
                      key={`tf_${index}`}
                      className="pricing-details-line tax-line"
                    >
                      <p>{value}</p>
                      <p className="line-amount">{cost}</p>
                    </div>
                  ))}
                </div>
              ) : null}
              <div className="pricing-details-line total">
                <p>{labels.total}</p>
                <p className="line-amount">
                  {formatPriceByLanguage(departurePriceData?.total, language)}
                </p>
              </div>
            </div>
          </Fragment>
        )}
      </ConfigConsumer>
    </div>
  )
}

const BookingDetailsModal = ({ isOpen, onRequestClose, bookingDetails }) => {
  const dispatch = useDispatch()
  const labels = useSelector(selectLabels)
  const language = useSelector(selectLanguage)
  const sessionId = useSelector(selectSessionId)
  const customerAccount = useSelector(selectCustomerAccount)
  const fields = useSelector(selectFields)
  const sendingEmailConfirmation = useSelector(selectSendingEmailConfirmation)
  const sendEmailConfirmationSuccess = useSelector(
    selectSendEmailConfirmationSuccess
  )
  const sendEmailConfirmationError = useSelector(
    selectSendEmailConfirmationError
  )

  if (!bookingDetails) return null

  const { bookingNumber, reservationTotals, bookingStatus } = bookingDetails
  const isCancelled = bookingStatus === bookingStatusCodes.cancelled
  const status = labels[bookingStatus] || bookingStatus
  const tripTotalWithoutPaid = +reservationTotals?.totalWithoutPaid
  const tripPaid = +reservationTotals?.paid
  const clientId = process.env.REACT_APP_FLOW_CLIENT_ID
  const renderSendConfirmation =
    bookingDetails?.canSendEmailConfirmation &&
    bookingDetails?.bookingNumber &&
    sessionId &&
    clientId

  const fieldDetails = filterFieldsByName(fields, ['emailAddress'])
  const validationSchema = getSchemaFromFieldDetails(fieldDetails)
  const initialValues = getInitialValuesFromFields(fieldDetails)
  initialValues.emailAddress = customerAccount?.email || ''

  const getSubmitButtonLabel = () => {
    // check confirmation success first
    if (sendEmailConfirmationSuccess) {
      return labels.sendConfirmationSuccessful
    }
    // check error then:
    if (sendEmailConfirmationError) {
      return labels.sendConfirmationError
    }
    // check sending state...
    if (sendingEmailConfirmation) {
      return labels.sending
    }
    // send confirmation by default
    return labels.sendConfirmationEmail
  }

  const handleSubmit = formValues => {
    dispatch(
      sendEmailConfirmation({
        bookingNumber: bookingDetails.bookingNumber,
        emailAddress: formValues.emailAddress
      })
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="booking-details-modal u-container"
    >
      <div className="booking-details-modal-body ">
        <button
          className="btn close-btn close-booking-modal"
          onClick={onRequestClose}
        >
          {labels.close} X
        </button>
        <div className="booking-details-modal-header">
          <h1>
            {labels.booking} {bookingNumber}
          </h1>
          <h6>
            <strong>{labels.bookingStatus}:</strong> {status}
          </h6>
          {renderSendConfirmation && (
            <ModalHeaderForm
              fieldDetails={fieldDetails}
              initialValues={initialValues}
              handleSubmit={handleSubmit}
              validationSchema={validationSchema}
              submitButtonClasses={
                sendEmailConfirmationSuccess || sendEmailConfirmationError
                  ? 'btn btn-highlight btn-highlight-temp-success submit-btn'
                  : 'btn btn-highlight submit-btn'
              }
              disabled={
                sendingEmailConfirmation ||
                sendEmailConfirmationSuccess ||
                sendEmailConfirmationError
              }
              submitLabel={getSubmitButtonLabel()}
            />
          )}
        </div>
        <div className="booking-details-modal-main">
          <div className="trips-container">
            <div className="trips">
              <div className="trip departure">
                <Trip trip={bookingDetails?.departureRoute} labels={labels} />
              </div>
              {bookingDetails?.returnRoute && (
                <div className="trip return">
                  <Trip trip={bookingDetails.returnRoute} labels={labels} />
                </div>
              )}
            </div>
            {reservationTotals?.total && (
              <div className="booking-totals">
                {!isCancelled && (
                  <Fragment>
                    <div className="booking-total">
                      <p>{labels.tripTotal}</p>
                      <p>
                        {formatPriceByLanguage(tripTotalWithoutPaid, language)}
                      </p>
                    </div>
                    <div className="booking-total-additional-line">
                      <p>{labels.paidAmount}</p>
                      <p>{formatPriceByLanguage(tripPaid, language)}</p>
                    </div>
                  </Fragment>
                )}
                <div className="booking-total-additional-line">
                  <p>{labels.balanceDue}</p>
                  <p>
                    {formatPriceByLanguage(
                      isCancelled ? 0 : +tripTotalWithoutPaid - +tripPaid,
                      language
                    )}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

BookingDetailsModal.propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func.isRequired,
  bookingDetails: shape().isRequired
}

export default BookingDetailsModal
