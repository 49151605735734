import { createSelector } from 'reselect'

import { ENABLE_EXTRAS } from '../../configuration/constants'
import { appRoutes } from '../../configuration/constants'
import { formatPriceByLanguage } from '../configuration/utilities'
import { getFaresString } from '../../configuration/utilities'
import {
  selectDepartureRoute,
  selectReturnRoute
} from '../ferryRouteSelections/selectors'
import {
  selectDepartureVehicleLength,
  selectReturnVehicleLength
} from '../vehicleSelections/selectors'
import { selectActiveModifyBooking } from '../modifyBooking/selectors'
import { selectPassengerSelections } from '../passengerSelections/selectors'
import { selectBookingConfirmationData } from '../booking/selectors'
import { selectLabels } from '../configuration/selectors'
import { selectLanguage } from '../session/selectors'

import {
  getDateValue,
  getTotalFromPriceLines
} from '../../components/summary/utilities'

export const selectSummaryPriceBreakdown = createSelector(
  selectDepartureRoute,
  selectReturnRoute,
  selectDepartureVehicleLength,
  selectReturnVehicleLength,
  selectActiveModifyBooking,
  selectPassengerSelections,
  selectBookingConfirmationData,
  selectLabels,
  selectLanguage,
  (
    activeDepartureRoute,
    activeReturnRoute,
    departureVehicleLength,
    returnVehicleLength,
    activeModifyBooking,
    passengers,
    bookingData,
    labels,
    language
  ) => {
    const bookingDataDeparture = bookingData?.departures?.length
      ? bookingData.departures.find(
          route => route.routeCode === activeDepartureRoute?.code
        )
      : null
    const bookingDataReturn = bookingData?.departures?.length
      ? bookingData.departures.find(
          route => route.routeCode === activeReturnRoute?.code
        )
      : null

    return {
      departure: getRouteBreakdown(
        bookingDataDeparture,
        labels,
        language,
        passengers.departure,
        activeModifyBooking?.departureRoute,
        departureVehicleLength
      ),
      return: getRouteBreakdown(
        bookingDataReturn,
        labels,
        language,
        passengers.return,
        activeModifyBooking?.returnRoute,
        returnVehicleLength
      )
    }
  }
)

export function getRouteBreakdown(
  route,
  labels,
  language,
  passengers,
  modifyBookingRoute,
  vehicleLength
) {
  if (!route) {
    return null
  }

  const {
    departurePriceData,
    passengerAddonPriceLines,
    vehicleAddonPriceLines,
    amendmentFeePriceLines,
    addOnPriceLines
  } = route

  if (!departurePriceData || !labels || !language) {
    if (process.env.REACT_APP_VERB_ENV !== 'production') {
      console.warn('Malformed getRouteBreakdown parameters')
    }
    return null
  }

  const { adult, senior, child, infant, student } = passengers
  const vehicleDetails = route.vehicles?.booked?.[0]

  const accommodationDisplayStrings = route.accommodationPriceLines?.length
    ? route.accommodationPriceLines.map(
        accommodation =>
          `${+accommodation.amount} - ${labels[accommodation.resourceCode]}`
      )
    : null
  const accommodationTaxSections = (route.accommodationPriceLines || [])
    .map(line => {
      const { tax, taxPercentage, resourceCode } = line

      if (tax && +tax > 0) {
        return {
          value: `${labels[resourceCode]} (${+taxPercentage}%)`,
          cost: formatPriceByLanguage(tax, language),
          rawCost: +tax
        }
      } else {
        return null
      }
    })
    .filter(x => !!x)
  const addonFeeSections = [
    ...(passengerAddonPriceLines || []),
    ...(vehicleAddonPriceLines || [])
  ].map(line => {
    const { price, resourceCode } = line
    return {
      value: labels[resourceCode],
      cost: formatPriceByLanguage(price, language),
      rawCost: +price
    }
  })
  const amendmentFeeSections = (amendmentFeePriceLines || []).map(line => {
    const { price, resourceCode } = line
    return {
      value: labels[resourceCode] || labels.amendmentFee,
      cost: formatPriceByLanguage(price, language),
      rawCost: +price
    }
  })
  // NOTE(ebarrett): taxesAndLevies is a CTMA-only construct, consists of a object map of tax key to cost.
  //  For NFL and MAI, it's returned as an empty array instead, thus the check below
  const leviesMap =
    typeof departurePriceData.taxesAndLevies === 'object' &&
    !Array.isArray(departurePriceData.taxesAndLevies)
      ? departurePriceData.taxesAndLevies
      : {}
  const leviesSections = Object.entries(leviesMap).map(
    ([taxItemKey, price]) => {
      return {
        value: labels[taxItemKey] || labels.amendmentFee,
        cost: formatPriceByLanguage(price, language),
        rawCost: +price
      }
    }
  )

  const getPriceLinesRawCost = lines =>
    lines && lines.length ? getTotalFromPriceLines(lines) : 0
  const formatPriceLines = lines =>
    formatPriceByLanguage(getPriceLinesRawCost(lines), language)
  // as of writing this comment only MAI uses the label dateTime and it will be undefined for other clients.
  const dateSection = {
    label: labels.dateTime || labels.date,
    value: getDateValue(route, labels, language),
    cost: null,
    iconType: 'calendar',
    changeRoute: appRoutes.ferryRoutes.pathname
  }

  const petsData = addOnPriceLines.find(p => p.resourceCode === 'PET')
  let pet = null
  if (petsData) {
    pet = petsData.amount
  }

  const passengersSection = {
    label: labels.passengers,
    value: getFaresString({
      labels,
      language,
      adults: adult,
      seniors: senior,
      children: child,
      infants: infant,
      students: student,
      pets: pet
    }),
    cost: formatPriceLines(route.passengerPriceLines),
    rawCost: getPriceLinesRawCost(route.passengerPriceLines),
    iconType: 'passenger',
    changeRoute: appRoutes.passengerQuantities.pathname
  }
  const vehicleSection = {
    label: labels.vehicle,
    value: vehicleDetails
      ? `${labels[vehicleDetails.resourceCode]}${
          +vehicleLength > 21 ? ` (${vehicleLength} ${labels.feet})` : ''
        }`
      : labels.noVehicle,
    cost: formatPriceLines(route.vehiclePriceLines),
    rawCost: getPriceLinesRawCost(route.vehiclePriceLines),
    iconType: 'vehicle',
    changeRoute: appRoutes.vehicle.pathname
  }
  const accommodationsSection = {
    label: labels.accommodations,
    value: accommodationDisplayStrings?.length
      ? accommodationDisplayStrings.join(', ')
      : labels.noAccommodationsSelected,
    cost: formatPriceLines(route.accommodationPriceLines),
    rawCost: getPriceLinesRawCost(route.accommodationPriceLines),
    iconType: ENABLE_EXTRAS ? 'extras' : 'accommodations',
    changeRoute: appRoutes.accommodations.pathname
  }

  const discountSection =
    +departurePriceData.discount > 0
      ? {
          label: labels.discounts,
          value: '',
          cost: formatPriceByLanguage(departurePriceData.discount),
          rawCost: departurePriceData.discount
        }
      : null
  const taxesAndFeesSections = [
    ...accommodationTaxSections,
    ...addonFeeSections,
    ...amendmentFeeSections,
    ...leviesSections
  ]

  const tripTotal = +departurePriceData.total
  const alreadyPaid = modifyBookingRoute
    ? +modifyBookingRoute.departurePriceData.total
    : false
  const balanceDue = alreadyPaid ? tripTotal - alreadyPaid : tripTotal

  return {
    dateSection,
    passengersSection,
    vehicleSection,
    accommodationsSection,
    discountSection,
    taxesAndFeesSections,
    tripTotal,
    alreadyPaid,
    balanceDue
  }
}
