import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import ClickAwayListener from '../../ClickAwayListener/ClickAwayListener'
import _ from 'lodash'

import ConfigConsumer from '../../../redux/configuration/context'
import SvgIcon from '../../SvgIcon/SvgIcon'
import { useSelector } from 'react-redux'
import { selectLabels } from '../../../redux/configuration/selectors'
import { renderRouteLabel } from '../utilities'

const FerryRoutesFormSelect = ({
  activeRoute,
  ferryRoutes,
  fieldLabel,
  updateRouteEvent,
  disableToggle
}) => {
  const [isFieldToggled, toggleField] = useState(false)
  const [itemCursor, setItemCursor] = useState(-1)
  const listRef = useRef(null)
  const labels = useSelector(selectLabels)
  let renderOptions = []

  const handleFieldClick = () => {
    if (!disableToggle) {
      toggleField(!isFieldToggled)
    }
  }

  // handle navigating the list by iterating up or down, skipping disabled elements
  const handleKeyboardNav = (direction = 1) => {
    // items aren't loaded, or only 1 item exists
    if (
      !listRef?.current?.childNodes ||
      listRef.current.childNodes.length === 1
    ) {
      return
    }

    let nextItem = itemCursor
    if (Number(direction) !== 0) {
      let totalIteratedItems = 0
      const listLength = listRef.current.childNodes.length
      while (Math.abs(totalIteratedItems) < listLength) {
        nextItem += Number(direction)
        totalIteratedItems += Number(direction)

        if (nextItem >= listLength) {
          nextItem = 0
        } else if (nextItem < 0) {
          nextItem = listLength - 1
        }

        // we found a valid item
        if (!listRef.current.childNodes[nextItem].disabled) {
          break
        }
      }
    }

    setItemCursor(nextItem)
    listRef.current.childNodes[nextItem].focus()
  }

  useEffect(() => {
    const handleInput = keyEvent => {
      if (!isFieldToggled) {
        return
      }

      if (keyEvent.code === 'Tab') {
        toggleField(false)
      } else if (keyEvent.code === 'Esc') {
        keyEvent.preventDefault()
        toggleField(false)
      } else if (
        keyEvent.code === 'ArrowDown' ||
        keyEvent.code === 'ArrowRight'
      ) {
        keyEvent.preventDefault()
        if (!keyEvent.repeat) {
          if (listRef?.current?.childNodes) {
            handleKeyboardNav(1)
          }
        }
      } else if (keyEvent.code === 'ArrowUp' || keyEvent.code === 'ArrowLeft') {
        keyEvent.preventDefault()
        if (!keyEvent.repeat) {
          if (listRef?.current?.childNodes) {
            handleKeyboardNav(-1)
          }
        }
      }
    }

    document.addEventListener('keydown', handleInput)
    return () => {
      document.removeEventListener('keydown', handleInput)
    }
  })

  if (!_.isEmpty(ferryRoutes)) {
    for (const routeKey in ferryRoutes) {
      const currentRoute = ferryRoutes[routeKey]
      if (currentRoute?.code && currentRoute?.description) {
        renderOptions.push(
          <button
            disabled={String(currentRoute?.code) === String(activeRoute?.code)}
            className={'ferry-routes__form-select-option btn alt-dropdown-btn'}
            key={'route-option-' + currentRoute.code}
            onMouseDown={mouseEvent => {
              mouseEvent.stopPropagation()
            }}
            onClick={() => {
              updateRouteEvent(currentRoute)
              toggleField(false)
            }}
            aria-label={renderRouteLabel(currentRoute, labels)}
          >
            {renderRouteLabel(currentRoute, labels)}
          </button>
        )
      }
    }
  }

  return (
    <ClickAwayListener toggleEvent={toggleField}>
      <div
        className="ferry-routes__form-select"
        onMouseDown={handleFieldClick}
        tabIndex={0}
        aria-label={`${fieldLabel}, ${
          activeRoute?.description
            ? renderRouteLabel(activeRoute, labels)
            : labels.selectRoute
        }`}
        onFocus={keyEvent => {
          if (!isFieldToggled) {
            keyEvent.preventDefault()
            toggleField(true)
          }
        }}
      >
        <div
          className={`u-form__custom-select ${disableToggle ? 'disabled' : ''}`}
        >
          <p className="u-form__custom-select-label theme-title-variant-seven">
            {fieldLabel}
          </p>
          {activeRoute?.description ? (
            <div
              className="u-form__custom-select-field"
              aria-label={fieldLabel}
            >
              <p className="select-value">
                <ConfigConsumer>
                  {({ themeVariables }) => (
                    <SvgIcon
                      className="select-value-icon"
                      type="routes"
                      fill={themeVariables.themeIconColor}
                    />
                  )}
                </ConfigConsumer>
                {renderRouteLabel(activeRoute, labels)}
              </p>
              {!disableToggle && <p className="select-toggle" />}
            </div>
          ) : (
            <div className="u-form__custom-select-field">
              <p className="select-value">
                <ConfigConsumer>
                  {({ themeVariables }) => (
                    <SvgIcon
                      className="select-value-icon"
                      type="routes"
                      fill={themeVariables.themeIconColor}
                    />
                  )}
                </ConfigConsumer>{' '}
                {labels.selectRoute}{' '}
              </p>
              <p className="select-toggle" tabIndex={-1} />
            </div>
          )}
        </div>
        {isFieldToggled && !_.isEmpty(renderOptions) && (
          <div
            className={'ferry-routes__form-select-alt-options'}
            ref={listRef}
          >
            {renderOptions}
          </div>
        )}
      </div>
    </ClickAwayListener>
  )
}

FerryRoutesFormSelect.propTypes = {
  activeRoute: PropTypes.object,
  ferryRoutes: PropTypes.array.isRequired,
  fieldLabel: PropTypes.string,
  toggleLabel: PropTypes.string,
  updateRouteEvent: PropTypes.func.isRequired,
  disableToggle: PropTypes.bool
}

export default FerryRoutesFormSelect
